import React from 'react';
import EventSummaryInfo from './EventSummaryInfo';

const EventSummary = ({ event }) => (
  <div className="w-100 overflow-hidden rounded shadow-xl flex">
    <div 
      className="hidden md:block flex-1 bg-cover flex content-end bg-left pt-8" 
      style={{
        backgroundImage: `url(${event.summaryImage})`,
      }}
    />
    <EventSummaryInfo event={event} />
  </div>
);

export default EventSummary;
