import React from 'react';
import EventSummaryInfo from './EventSummaryInfo';

const FeaturedEventSummary = ({ event }) => (
  <div className="w-100 overflow-hidden rounded shadow-xl mb-4">
    <div 
      className="bg-cover flex flex-col content-end bg-center pt-8" 
      style={{
        backgroundImage: `url(${event.summaryImage})`, 
      }}
      >
      {event.logo && (
        <img src={event.logo} className="w-24 mt-8 ml-4" alt={`${event.name} Logo`} />
      )}
      <EventSummaryInfo className="mt-20" event={event} />
    </div>
  </div>
);

export default FeaturedEventSummary;
