import React from 'react';

const ArticleSummary = ({ title }) => (
  <div className="border-b pb-2 mb-8">
    <div className="text-xl font-bold">{title}</div>
    <div className="text-xs text-gray-600 border-b mb-2">20th January 2020</div>
    <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pellentesque dolor laoreet semper laoreet. Praesent interdum magna vel rhoncus aliquam. Suspendisse nec aliquam nunc. Quisque mattis porta dui, a commodo.</p> 
  </div>
);

export default ArticleSummary
