import React from 'react';

const Product = ({ product }) => (
  <div className="flex-1 max-w-xxs min-w-1/2 sm:min-w-1/3 lg:min-w-1/4 xl:min-w-1/5 pr-4 pb-4">
    <div 
      className="w-full bg-cover bg-center border border-gray-500 hover:opacity-75"
      style={{
        backgroundImage: `url(${product.images[0]})`,
        paddingTop: '100%',
      }}
    />
    <div className="text-center pt-2">{product.name}</div>
    <div className="text-center text-sm text-red-900">£{(product.price / 100).toFixed(2)}</div>
  </div>
);

export default Product;
