import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NotFound from './NotFound';
import Product from '../components/Product';
import {
  getFilmBySlug,
  getEventsByFilm,
  getProductsByTag,
} from '../data';

const ratings = [
  27.6,
  58.4,
  13.3,
  0.6,
  0.1,
];

const Film = ({ match }) => {
  const { slug } = match.params;

  const film = getFilmBySlug(slug);

  if (film === undefined) {
    return <NotFound />;
  }

  const products = getProductsByTag(film.name);

  const details = [];

  if (film.year) {
    details.push({
      name: 'Year',
      value: film.year,
    });
  }

  details.push({
    name: 'Runtime',
    value: film.runtime,
  });

  const events = getEventsByFilm(film.slug);

  return (
    <div>
      <Helmet>
        <title>{film.name} - Off Up</title>
      </Helmet>
      <div
        className="w-full h-48 sm:h-96 bg-cover bg-center border-b border-gray-500"
        style={{
          backgroundImage: `url(${film.hero})`,
        }}
      />
      <div className="flex px-8 film-info flex-wrap">
        <div className="flex-0 min-w-full sm:min-w-1/3 lg:min-w-1/4 sm:pr-8">
          <div className="w-1/3 sm:w-full">
            <img className="shadow-xl" src={film.poster} />
          </div>
          <div className="hidden sm:block">
            <ul className="mb-4 pt-4">
              <li className="lg:flex justify-between items-end border-b py-1">
                <div className="uppercase text-sm font-bold text-red-900">Rating</div>
                <div className="flex justify-between items-end">
                  ★★★★☆
                  <div className="text-red-900 text-lg font-bold pl-2">
                    4.1
                  </div>
                </div>
              </li>
              {details.map(detail => (
                <li className="lg:flex justify-between items-end border-b py-1">
                  <div className="uppercase text-sm font-bold text-red-900">{detail.name}</div>
                  <div>{detail.value}</div>
                </li>
              ))}
            </ul>
            {film.cast && film.cast.length > 0 && (
              <div>
                <h2 className="text-lg font-bold text-red-900">Cast</h2>
                <div className="flex mt-1 mb-2 flex-wrap justify-between text-sm">
                  {film.cast.join(', ')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex-2">
          <div className="sm:text-white sm:text-shadow text-shadow-none">
            <h1 className="text-4xl font-bold leading-tight mt-12">{film.name}</h1>
            <div>Directed by <span className="font-bold text-gray-400">{film.director}</span></div>
          </div>

          <div className="mt-8">
            {events.map(event => (
              <div className="bg-gray-200 mb-4 p-2 flex items-center">
                {event.logo && <img src={event.logo} className="w-20" />}
                <div className="pl-4">
                  <div className="uppercase text-xs font-bold text-gray-600">Showing at</div>
                  <div className="leading-none text-red-900 text-xl font-bold">
                    <Link to={`/events/${event.slug}`}>{event.name}</Link>
                    <div className="text-sm text-gray-900 leading-tight">{event.showingDate}</div>
                    <div className="text-sm font-bold text-gray-700 tracking-tight">{event.showingTime}</div>
                  </div>
                </div>
              </div>
            ))}
            {film.description.map(d => (
              <p>{d}</p>
            ))}
            <h2 className="text-sm font-bold text-red-900 mt-6">Photos</h2>
            <div className="flex mt-1 mb-4 flex-wrap">
              {film.photos.map((photo) => (
                <div className="mr-2 w-32 mb-2">
                  <div
                    className="w-full h-20 border border-gray-400 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${photo})`,
                    }}
                  />
                </div>
              ))}
            </div>

            {film.trailer && (
              <div className="my-8">
                <iframe
                  className="max-w-full h-56 sm:h-64 md:h-96"
                  width="560"
                  src={film.trailer}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            )}

            {products.length > 0 && (
              <div>
                <h2 className="text-sm font-bold text-red-900">Merchandise</h2>
                <div className="flex flex-wrap mt-1">
                  {products.map(product => <Product product={product} />)}
                </div>
              </div>
            )}
            <h2 className="text-2xl mb-4 flex justify-between">
              Reviews
            </h2>
            <div className="flex flex-wrap flex-col-reverse lg:flex-row">
              <div className="flex-1 pr-4">
                {film.reviews.map(() => (
                  <div className="mb-4 pb-2">
                    <div className="flex border-b pb-1 mb-1">
                      <div className="font-bold">User</div>
                      <div className="px-2 text-red-900">
                        ★★★★
                        <span className="text-gray-700">☆</span>
                      </div>
                      <div className="text-gray-600">2020-01-31</div>
                    </div>
                    <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin viverra eu elit in pulvinar. Cras maximus mauris at risus dictum, at auctor ipsum sollicitudin. In elementum convallis neque, non finibus. </div>
                  </div>
                ))}
              </div>
              <div className="flex-0 min-w-full lg:min-w-1/4">
                <div className="flex justify-between items-end mb-1">
                  ★★★★☆
                  <div className="text-red-900 text-lg font-bold pl-2">
                    4.1
                  </div>
                </div>
                {ratings.map((rating, index) => (
                  <div className="flex mb-1 items-center">
                    <div className="flex-0 mr-2 text-xs text-gray-800">{5 - index}</div>
                    <div className="flex-1 w-32 flex">
                      <div
                        style={{
                          width: `${rating}%`,
                        }}
                        className="bg-red-900 h-4"
                      />
                      <div className="text-xs text-gray-600 ml-1">{`${rating}%`}</div>
                    </div>
                  </div>
                ))}
                <button
                  aria-label="Not yet implemented."
                  data-balloon-pos="up"
                  className="btn text-sm w-full mb-4 mt-2"
                >
                  Write a review
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Film;
