import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NotFound from './NotFound';
import Page from '../components/Page';
import FeaturedEventSummary from '../components/FeaturedEventSummary';
import { getEventBySlug, getFilmBySlug } from '../data';

const Events = ({ match }) => {
  const { slug } = match.params;

  const evt = getEventBySlug(slug);

  if(evt === undefined) {
    return <NotFound />
  }

  const films = evt.films.map((f) => {
    return Object.assign({}, f, getFilmBySlug(f.slug));
  });

  const details = [
    {
      name: 'Date',
      value: evt.date,
    },
    {
      name: 'Time',
      value: evt.time
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{`${evt.name} - Events - Off Up`}</title>
      </Helmet>
      <div
        className="w-full h-48 sm:h-96 bg-cover bg-center border-b border-gray-500"
        style={{
          backgroundImage: `url(${evt.hero})`,
        }}
      />
      <div className="flex px-8 film-info flex-wrap pb-8">
        <div className="flex-0 min-w-full sm:min-w-1/3 lg:min-w-1/4 sm:pr-8">
          <div className="w-1/3 sm:w-full">
            <img className="shadow-xl" src={evt.poster} />
          </div>
          <ul className="mb-4 pt-4">
            {details.map(detail => (
              <li className="lg:flex justify-between items-end border-b py-1">
                <div className="uppercase text-sm font-bold text-red-900">{detail.name}</div>
                <div>{detail.value}</div>
              </li>
            ))}
          </ul>
          <button
            aria-label="Not yet implemented."
            data-balloon-pos="up"
            className="btn w-full"
          >
            Buy Tickets
          </button>
        </div>
        <div className="flex-2">
          <div className="sm:text-white sm:text-shadow text-shadow-none">
            <h1 className="text-4xl font-bold leading-tight mt-12">{evt.name}</h1>
            <div>{evt.date}</div>
          </div>
          <p className="mt-8 mb-4">{evt.description}</p>
          {evt.trailer && (
            <video
              autoplay="autoplay"
              muted="muted"
              className="max-w-full h-56 sm:h-64 md:h-96 border"
              width="560"
              controls
            >
              <source src={evt.trailer} type="video/mp4" />
            </video>
          )}
          <h2 className="text-3xl mt-4 mb-2">Lineup</h2>
          <div className="flex flex-wrap">
            {films.map(film => (
              <div className="min-w-1/3 md:min-w-1/4 w-32 pr-6 mb-4">
                <Link to={`/films/${film.slug}`}>
                  <div className="w-full">
                    <img className="w-full shadow-xl border border-gray-400" src={film.poster} />
                  </div>
                  <div className="text-center mt-1">
                    <div className="">{film.name}</div>
                    <div className="text-xs text-gray-700">{film.date}</div>
                    <div className="text-sm font-bold text-red-900">{film.time}</div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
