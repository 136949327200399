import React from 'react';
import { Helmet } from 'react-helmet';


const NotFound = () => (
  <div>
    <Helmet>
      <title>Not Found - Off Up</title>
    </Helmet>
    <h1 className="text-center text-xl font-bold py-6">Not found</h1>
  </div>
);

export default NotFound;
