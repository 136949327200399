import React from 'react';

const EventSummaryInfo = ({ event }) => (
  <div className="flex-2 px-4 w-full" style={{ backgroundColor: '#ffffff99' }}>
    <div className="pt-4 text-3xl font-bold leading-none">{event.name}</div>
    <div className="text-sm leading-tight">{event.date}</div>
    <div className="text-sm font-bold text-gray-700 tracking-tight">{event.time}</div>
    <p className="my-4">{event.description}</p>
    <div className="flex pb-4 mt-2">
      <button className="btn">Lineup</button>
      <button className="btn ml-3">Tickets</button>
    </div>
  </div>
);

export default EventSummaryInfo;

