import React from 'react';
import { Helmet } from 'react-helmet';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import EventList from '../components/EventList';

const Events = () => (
  <Page>
    <div className="pb-5">
      <Helmet>
        <title>Events - Off Up</title>
      </Helmet>
      <PageHeading>Events</PageHeading>
      <div className="flex-2">
        <EventList />
      </div>
    </div>
  </Page>
);

export default Events;
