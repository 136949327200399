export const events = [
  {
    logo: '/images/chanimation-logo.png',
    name: 'Chanimation Film Festival',
    slug: 'chanimation',
    date: '25th January 2020',
    time: '12:00-20:00',
    trailer: '/images/trailer.mp4',
    featured: true,
    summaryImage: '/images/chanimation-hero.jpg',
    hero: '/images/chanimation-hero2.jpg',
    poster: '/images/chanimation-flyer.jpg',
    description: 'Chinese animation films event. Cross culture. For all ages.',
    films: [
      {
        slug: 'she',
        date: '25th January 2020',
        time: '12:00',
      },
      {
        slug: 'tea-pets',
        date: '25th January 2020',
        time: '14:00',
      },
      {
        slug: 'short-film-pack',
        date: '25th January 2020',
        time: '16:00',
      },
      {
        slug: 'white-snake',
        date: '25th January 2020',
        time: '18:00',
      },
    ],
  },
  {
    name: 'Other Events',
    slug: 'other',
    disabled: true,
    date: '1st February 2020',
    time: '12:00-16:00',
    summaryImage: '/images/chindie.jpg',
    hero: '/images/chanimation-hero.jpg',
    poster: '/images/chanimation-poster.jpg',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eu quam auctor, consectetur odio semper, tempus sapien.',
    films: [],
  },
];

const films = [
  {
    name: 'White Snake',
    slug: 'white-snake',
    year: '2019',
    runtime: '1h 39min',
    director: 'Amp Wong & Zhao Ji',
    cast: [
      'Vincent Rodriguez III',
      'Faye Mata',
      'Matthew Moy',
    ],
    description: ['The story follows the Chinese fable the Legend of the White Snake. The focus is on a snake-demon Blanca who loses her memory while being disguised as a human woman and falls in love with a snake hunter. This strongly displeases her sister, Verta, the green snake-demon.'],
    poster: '/images/white-snake-poster.jpg',
    hero: '/images/white-snake-hero.jpg',
    photos: [
      '/images/white-snake-1.jpg',
      '/images/white-snake-2.jpg',
      '/images/white-snake-3.jpg',
      '/images/white-snake-4.jpg',
      '/images/white-snake-5.jpg',
      '/images/white-snake-6.jpg',
    ],
    trailer: 'https://www.youtube.com/embed/JeTy_t4WAfo',
    reviews: [1, 2, 3, 4],
  },
  {
    name: 'Tea Pets',
    slug: 'tea-pets',
    year: '2017',
    runtime: '1h 38min',
    director: 'Amp Wong & Zhao Ji',
    cast: ['Guanlin Ji', 'Jason Kesser'],
    description: ['A group of tea pets-cute clay figurines that are good-luck charms for tea drinkers-embark on a magical animated adventure to find a fabled mystic.'],
    poster: '/images/tea-pets-poster.jpg',
    hero: '/images/tea-pets-hero.jpg',
    photos: [
      '/images/tea-pets-1.jpg',
      '/images/tea-pets-2.jpg',
      '/images/tea-pets-3.jpg',
      '/images/tea-pets-4.jpg',
      '/images/tea-pets-5.jpg',
      '/images/tea-pets-6.jpg',
    ],
    trailer: 'https://www.youtube.com/embed/DE8tVZ-ls_Y',
    reviews: [1, 2, 3, 4],
  },
  {
    name: 'Short Film Pack',
    slug: 'short-film-pack',
    runtime: '1h',
    director: 'Various',
    description: [
      '1. Sacrifice at Guang Sheng Temple.',
      '2. AQUA.',
      '3. Morden Babel.',
      '4. The Dictionary.',
      '5. June 7-8, 2020.',
      '6. Sparky.',
      '7. Me and my magnet and my dead friend.',
    ],
    poster: '/images/short-poster.jpg',
    hero: '/images/short-hero.jpg',
    photos: [
      '/images/short-1.jpg',
      '/images/short-2.jpg',
      '/images/short-3.jpg',
    ],
    reviews: [1, 2, 3, 4],
  },
  {
    name: 'SHe',
    slug: 'she',
    runtime: '1h 35min',
    year: '2018',
    director: 'Shengwei Zhou',
    cast: [
      'Fuyang Lyu',
      'Shengwei Zhou',
    ],
    description: ['A high heel mother shoe survives in an authoritarian world dominated by male shoes and brings up her daughter shoe by disguising herself as a male shoe.'],
    poster: '/images/she-poster.jpg',
    hero: '/images/she-hero.jpg',
    photos: [
      '/images/she-1.jpg',
      '/images/she-2.jpg',
      '/images/she-3.jpg',
      '/images/she-4.jpg',
      '/images/she-5.jpg',
      '/images/she-6.jpg',
    ],
    reviews: [1, 2, 3, 4],
  },
];

export const products = [
  {
    slug: 'hair-pin',
    name: 'Hair Pin',
    price: 150,
    images: [
      '/products/white-snake/hairpin/hairpin-1.jpg',
      '/products/white-snake/hairpin/hairpin-2.jpg',
      '/products/white-snake/hairpin/hairpin-3.jpg',
      '/products/white-snake/hairpin/hairpin-4.jpg',
    ],
    tags: [
      'White Snake',
    ],
  },
  {
    slug: 'bookmarks',
    name: 'Leaf Bookmark',
    price: 150,
    images: [
      '/products/white-snake/bookmarks/leaf-bookmark-1.png',
      '/products/white-snake/bookmarks/leaf-bookmark-2.png',
      '/products/white-snake/bookmarks/leaf-bookmark-3.png',
      '/products/white-snake/bookmarks/leaf-bookmark-4.png',
      '/products/white-snake/bookmarks/leaf-bookmark-5.png',
      '/products/white-snake/bookmarks/leaf-bookmark-6.png',
    ],
    tags: [
      'White Snake',
    ],
  },
  {
    slug: 'white-snake-badges',
    name: 'White Snake Badges',
    price: 150,
    images: [
      '/products/white-snake/badges.jpg',
    ],
    tags: [
      'White Snake',
    ],
  },
  {
    slug: 'deer-tea-pet',
    name: 'Deer Tea Pet',
    price: 150,
    images: [
      '/products/tea-pets/deer-1.jpg',
      '/products/tea-pets/deer-2.png',
      '/products/tea-pets/deer-2.png',
    ],
    tags: [
      'Tea Pets',
    ],
  },
  {
    slug: 'pig-tea-pet',
    name: 'Pig Tea Pet',
    price: 150,
    images: [
      '/products/tea-pets/pig-1.jpg',
      '/products/tea-pets/pig-2.png',
      '/products/tea-pets/pig-3.png',
    ],
    tags: [
      'Tea Pets',
    ],
  },
  {
    slug: 'mini-tea-pot',
    name: 'Mini Tea Pot',
    price: 150,
    images: [
      '/products/tea-pets/tea-pot-1.jpg',
      '/products/tea-pets/tea-pot-2.jpg',
      '/products/tea-pets/tea-pot-3.png',
      '/products/tea-pets/tea-pot-4.png',
    ],
    tags: [
      'Tea Pets',
    ],
  },
  {
    slug: 'toad-tea-pet',
    name: 'Toad Tea Pet',
    price: 150,
    images: [
      '/products/tea-pets/toad-1.jpg',
      '/products/tea-pets/toad-2.jpg',
      '/products/tea-pets/toad-3.jpg',
    ],
    tags: [
      'Tea Pets',
    ],
  },
];

export const productTags = [
  'White Snake',
  'Tea Pets',
];

export const getEventBySlug = slug => events.find(item => item.slug === slug);
export const getFilmBySlug = slug => films.find(item => item.slug === slug);
export const getEventsByFilm = (filmSlug) => {
  const filteredEvents = events
  .map(event => Object.assign({}, event, { films: event.films.filter(item => item.slug === filmSlug) }))
  .filter(event => event.films.length === 1)
  .map(event => Object.assign({}, event, {
    showingDate: event.films[0].date,
    showingTime: event.films[0].time,
  }));

  return filteredEvents;
};

export const getProductsByTag = tag => products.filter(item => item.tags.indexOf(tag) !== -1);
