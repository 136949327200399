import React from 'react';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import EventList from '../components/EventList';
import ArticleSummary from '../components/ArticleSummary';

const Home = () => (
  <Page>
    <div className="flex w-100 pb-5 flex-wrap">
      <div className="flex-2 min-w-full lg:min-w-0">
        <EventList />
      </div>
      <div className="flex-1 text-center px-4 pt-8 lg:pt-0">
        <PageHeading>News</PageHeading>
        <div className="text-left">
          <ArticleSummary title="Article 1" />
          <ArticleSummary title="Article 2" />
          <ArticleSummary title="Article 3" />
        </div>
      </div>
    </div>
  </Page>
);

export default Home;
