import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './pages/Home';
import Events from './pages/Events';
import Event from './pages/Event';
import Film from './pages/Film';
import NotFound from './pages/NotFound';
import Shop from './pages/Shop';

const links = [
  {
    text: 'Events',
    href: '/events',
  },
  {
    text: 'Shop',
    href: '/shop',
  },
];

const App = () => {
  return (
    <Router>
      <div className="container bg-white m-auto text-gray-900">
        <header className="bg-black flex items-center justify-between">
          <div className="text-gray-300 text-lg font-bold uppercase tracking-tight">
            <Link to="/">
              <img className="h-12 pl-1" src="/images/offup-logo.png" border="0" alt="OFF UP" />
            </Link>
          </div>
          <nav className="ml-4 p-4">
            <ul className="flex">
              {links.map(link => (
                <li className="ml-2 p-0 m-0">
                  <Link 
                    to={link.href} 
                    className="text-white hover:text-gray-200 rounded hover:bg-gray-800 p-2"
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
              <li className="ml-2 p-0 m-0 hidden sm:block">
                <span 
                  aria-label="Not yet implemented."
                  data-balloon-pos="down"
                  className="text-white hover:text-gray-200 rounded hover:bg-gray-800 p-2"
                >
                  Contact
                </span>
              </li>
            </ul>
          </nav>
        </header>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/events" component={Events} exact />
          <Route path="/films/:slug" component={Film} exact />
          <Route path="/events/:slug" component={Event} exact />
          <Route path="/shop" component={Shop} exact />
          <NotFound />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
