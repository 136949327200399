import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Product from '../components/Product';
import { 
  products,
  productTags,
} from '../data';

export default class Shop extends React.Component{
  constructor() {
    super();

    this.state = {
      selectedTags: [],
    };
  }

  onToggleTag(tag) {
    const { selectedTags } = this.state;

    if (selectedTags.indexOf(tag) !== -1) {
      this.setState({
        selectedTags: selectedTags.filter(item => item !== tag),
      });
    } else {
      this.setState({
        selectedTags: [tag],
      });
    }
  }

  render() {
    const { selectedTags } = this.state;

    let filteredProducts = products;
    if(selectedTags.length > 0) {
      filteredProducts = filteredProducts.filter(item => item.tags.indexOf(selectedTags[0]) !== -1);
    }

    return (
      <Page>
        <div className="pb-5">
          <Helmet>
            <title>Shop - Off Up</title>
          </Helmet>
          <PageHeading>Shop</PageHeading>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus posuere nisl eget velit dapibus, et gravida purus bibendum. Ut nec condimentum sem. Vestibulum placerat placerat.</p>
          <div className="flex mt-8 flex-wrap">
            <div className="w-full sm:w-1/4 pb-4">
              <div className="text-sm font-bold text-red-900">Filter</div>
              <ul>
                {productTags.map(tag => (
                  <li
                    onClick={() => this.onToggleTag(tag)}
                    className={classNames('cursor-pointer', {
                      'opacity-50': selectedTags.length > 0 && selectedTags.indexOf(tag) === -1,
                      'font-bold': selectedTags.indexOf(tag) !== -1,
                    })}
                  >
                    {tag}
                  </li>
                ))}
              </ul>
              
            </div>
            <div className="flex-1 flex flex-wrap">
              {filteredProducts.map(product => (
                <Product product={product} />
              ))}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
