import React from 'react';
import { Link } from 'react-router-dom';
import FeaturedEventSummary from './FeaturedEventSummary';
import EventSummary from './EventSummary';
import { events } from '../data';

const EventList = () => (
  <div className="w-full">
    {events.map(event => (
      <Link to={event.disabled !== true && `/events/${event.slug}`}>
        {event.featured && <FeaturedEventSummary key={event.slug} event={event} />}
        {!event.featured && <EventSummary key={event.slug} event={event} />}
      </Link>
    ))}
  </div>
);

export default EventList;
